import React from "react"
// import { Link } from "gatsby"
import Headshot from "../images/headshot.jpg";

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="about-page">
      <div className="heading">
        <h1>About</h1>
      </div>
      <div class="about-body">
        <div class="text-container">
          <p>With over 10 years in the industry, I enjoy coding to solve problems, help products sell, and bring brands into the online world. I’m constantly learning new skills to create interactive and intuitive experiences that people love.</p>
          <p>I’m originally from Cuba but have been traveling and living in the US for a good chunk of time. Right now, I'm based in North Carolina.</p>
        </div>
        <div class="image-container">
          <img src={Headshot} />
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
